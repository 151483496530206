<template>
  <v-tab
    v-show="showTab"
    :href="`#${tab.key}`"
    @click="changeTab"
  >
    <v-badge
      :color="badgeColor"
      :content="badgeContent"
      :value="showBadge"
      :dot="badgeDot"
    >
      {{ tab.label| t }}
    </v-badge>
  </v-tab>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
    tab: Object,
    index: Number,
  },
  methods: {
    changeTab() {
      const query = { tab: this.tab.key }
      if(window.eagleLodash.isEqual(this.$route.query, query)) return
      this.$router.push({
        query,
      })
    },
  },
  computed: {
    showBadge() {
      if(typeof this.tab.badge != 'function') return false
      return this.tab.badge(this.formInfo) === true
    },
    badgeContent() {
      if(typeof this.tab.badgeContent != 'function') return null
      const content = this.tab.badgeContent(this.formInfo)
      if(!content) return null
      return this.$t(content)
    },
    badgeDot() {
      if(!this.badgeContent) return true
      return false
    },
    badgeColor() {
      const defaultColor = 'orange'
      if(typeof this.tab.badgeColor != 'function') return defaultColor
      return this.tab.badgeColor(this.formInfo) || defaultColor
    },
    showTab() {
      if(typeof this.tab.create != 'function') return true
      return this.tab.create(this.formInfo)
    },
    formInfo() {
      return this.$store.getters[`form/${this.formKey}/info`]
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
